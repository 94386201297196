var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass:
        "login-wrapper d-flex align-items-center justify-content-center vw-100 vh-100",
      staticStyle: { background: "#f8f9f9" },
    },
    [
      _c(
        "main",
        {
          staticClass:
            "w-100 h-100 text-center d-flex align-items-center justify-content-center",
        },
        [
          _setup.loading
            ? _c(
                "div",
                [
                  _c("font-awesome-icon", {
                    attrs: { icon: "spinner", spin: "" },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          !_setup.loading
            ? _c(
                "div",
                {
                  staticClass: "card card-body py-5",
                  staticStyle: { "max-width": "400px" },
                },
                [
                  _c("h2", [_vm._v("Risikoprofil")]),
                  _vm._v(" "),
                  _c("p", [_vm._v("Login")]),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      attrs: { type: "button" },
                      on: { click: _setup.login },
                    },
                    [_vm._v("Jetzt einloggen")]
                  ),
                ]
              )
            : _vm._e(),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }