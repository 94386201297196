import { render, staticRenderFns } from "./login.vue?vue&type=template&id=6f42616c&scoped=true&"
import script from "./login.vue?vue&type=script&lang=ts&setup=true&"
export * from "./login.vue?vue&type=script&lang=ts&setup=true&"
import style0 from "./login.vue?vue&type=style&index=0&id=6f42616c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6f42616c",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/daniel/Desktop/business/EFM/02 RCP/code/rcp-frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6f42616c')) {
      api.createRecord('6f42616c', component.options)
    } else {
      api.reload('6f42616c', component.options)
    }
    module.hot.accept("./login.vue?vue&type=template&id=6f42616c&scoped=true&", function () {
      api.rerender('6f42616c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "app/pages/login/login.vue"
export default component.exports